import React, { Component } from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import SectionsData from "../data/sections.json"
import Image from "../components/image"
import Footer from "../components/footer"
import Want from "../components/want"
import Loader from "../components/loader"
import SEO from "../components/seo"

class FullpageWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sections: SectionsData,
      showPreLoader: true,
      currentSection: 0,
    }
  }

  afterRender() {
    setTimeout(() => {
      this.setState({
        showPreLoader: false,
      })
    }, 1000)
  }

  onLeave(section) {
    console.log(section)
  }

  render() {
    const preLoader = (
      <div
        className={
          "c-preloader " + (this.state.showPreLoader ? "is-visible" : "")
        }
      >
        <Loader />
      </div>
    )

    const sections = this.state.sections.map((section, index) => (
      <div
        key={section.id}
        className={
          "o-section section u-bg-" +
          section.backgroundColor +
          " o-section--" +
          section.number +
          (section.id <= this.state.currentSection ? " is-seen" : "")
        }
      >
        <div className="u-grid">
          <div className="o-section__image-wrapper">
            <div
              className="o-section__image"
              style={{
                width: section.imageWidth,
                maxWidth: section.imageWidth,
              }}
            >
              <Image alt={section.imageAlt} filename={section.image} />
            </div>
          </div>
          <header className="o-section__header">
            <span className="o-section-number">{section.number}</span>
            <h2 dangerouslySetInnerHTML={{ __html: section.title }}></h2>
          </header>
          <div
            className="o-section__content"
            dangerouslySetInnerHTML={{ __html: section.content }}
          ></div>
        </div>
      </div>
    ))

    return (
      <main className="o-section">
        <SEO title="A Digital Consultancy"></SEO>
        {preLoader}
        <ReactFullpage
          licenseKey={"C317040C-84BB40C3-8E568BB2-78860953"}
          navigation={true}
          recordHistory={true}
          // anchors = {['01','02','03','04','05','06','07']}
          navigationPosition={"right"}
          responsiveWidth={768}
          responsiveHeight={749}
          normalScrollElements=".ReactModal__Overlay, .c-menu"
          afterRender={this.afterRender.bind(this)}
          onLeave={this.onLeave.bind(this)}
          render={({ state, fullpageApi }) => {
            // Add this here a supdating state wbreaks fullpage.js
            if (state.lastEvent === "onLeave") {
              if (state.direction === "down") {
                console.log(state.origin.index)
                if (state.origin.index === 5) {
                  document.body.classList.add("at-footer")
                } else {
                  document.body.classList.remove("at-footer")
                }
              }
              if (state.direction === "up") {
                console.log(state.origin.index)
                if (state.origin.index === 6) {
                  document.body.classList.add("at-footer")
                } else {
                  document.body.classList.remove("at-footer")
                }
              }
            }

            return (
              <div id="fullpage-wrapper">
                <div className="o-section u-bg-blue section">
                  <div className="u-grid">
                    <h1 className="c-welcome">
                      We create digital experiences that simplify people's
                      lives.
                    </h1>
                    <Want />
                  </div>
                </div>
                {sections}
                <div className="o-section u-bg-black section fp-auto-height">
                  <Footer />
                </div>
              </div>
            )
          }}
        />
      </main>
    )
  }
}

export default FullpageWrapper
