import React, { Component } from 'react';

class Option extends Component {


    constructor(props) {
        super(props);
        this.setSelectedOption = this.setSelectedOption.bind(this);
    }

    setSelectedOption(option) {
        this.props.onSelectOption(option);            
    }

    render() {
        const list = this.props.optionsArray.map((item) =>(
            <li key={item.id} className={
                "c-want-list__item " + (item.active ? "is-selected" : "")
            }>
                <button onClick={() => this.setSelectedOption(item)} className={"c-want-list__link " + (item.active ? "is-selected" : "")}>{item.title}</button>
            </li>
        ));

        return (
            <ul className="c-want-list"> {list} </ul>
        );
    }
}

Option.defaultProps = {
    optionsArray: [],
};

export default Option