import React, { Component } from "react"
import OptionsData from "../../data/wantOptions.json"
import Options from  "./options"
import Result from  "./result"
import Close from "../../components/close"

class Want extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            optionsVisible: false,
            optionsShown: false,
            options: OptionsData,
            selectedOption: []
        };
        this.toggleOptions = this.toggleOptions.bind(this);
        this.selectOption = this.selectOption.bind(this);
    }

    toggleOptions() {
        this.setState({
            optionsShown: true
        });
        this.setState(prevState => ({
            optionsVisible: !prevState.optionsVisible
        }));

        if (!this.state.optionsVisible) {
            document.body.classList.add('options-open');
        } else {
            document.body.classList.remove('options-open');
        }
    }

    selectOption(option) {
        this.state.options.forEach(function(item){
            item.active = false
        });
        this.state.options.find(item => item.id === option.id).active = true;
        this.setState({
            selectedOption: option
        });
        this.toggleOptions();
    }

    render() {

        const optionsModal = (
            <div className={
                "c-want-modal " + (this.state.optionsVisible ? "is-visible" : "is-hidden")
              }>
                <div className="c-want-modal__inner">
                    <h2 className="c-want-modal__heading u-font-size-h3">What problem do you have?</h2>
                    <Options optionsArray={this.state.options} onSelectOption={this.selectOption} />
                    <button onClick={this.toggleOptions} className="c-want-modal__close o-close o-close--white">
                        <Close></Close>
                    </button>
                </div>
            </div>
        )        
             
        const selectedDetails = this.state.selectedOption.content
        const selectedLogos = this.state.selectedOption.logos
       
        const selectedResult = (
            <Result selectedDetails={selectedDetails} selectedLogos={selectedLogos}></Result>
        )
       
        return (
            <div className="c-want">

                <h2 className="u-font-size-h3">What do you want to do?</h2>

                <div className="c-want__chosen">
                    <button className="c-want__chosen-title" onClick={this.toggleOptions}>
                        {this.state.selectedOption.title ? this.state.selectedOption.title : "I want to (please select)"}
                    </button>
                </div>

                {this.state.optionsVisible || this.state.optionsShown ? optionsModal : ''} 
                
                <div className="c-want-result">
                    {selectedDetails ? selectedResult : ''}
                </div>

            </div>
        )
    }
}

export default Want