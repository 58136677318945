import React, { Component } from 'react';

class Result extends Component {

    render() {
  
        return (
           <>
                <div dangerouslySetInnerHTML={{ __html:this.props.selectedDetails}}></div>

                {this.props.selectedLogos ? (
                    <ul className="c-logo-list">
                        {this.props.selectedLogos.map(item => (
                            <li key={item.id} dangerouslySetInnerHTML={{ __html:item.image}}>
                            </li>
                        ))}
                    </ul>
                ) : null}
            </>
        );
    }
}

Result.defaultProps = {
    selectedDetails: [],
    selectedLogos: []
};

export default Result